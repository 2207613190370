<template>
<!-- Ce composant est utilisé dans l'édition des récoltes et des activités de transport -->
  <div>
    <!-- Unité -->
    <h4 v-if="isOnHarvest" class="subtitle-form">{{ $t("harvestStorage.list.unit") }}</h4>
    <b-row class="mb-2">
      <b-col >
        <b-select size="sm" :options="units" v-model="unitSelected" :required="isOnHarvest"></b-select>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Dropdown from "@/components/machines/details/Dropdown.vue";
import DetailUpdateMixin from "@/mixins/DetailUpdateMixin";
import LoadFormPartMixin from "@/mixins/LoadFormPartMixin";

export default {
  mixins: [DetailUpdateMixin, LoadFormPartMixin],
  data() {
    return {
      units: [
        { value: "kg", text: "kg" },
        { value: "q", text: "quintaux" },
        { value: "T", text: "tonnes" },
        { value: "b", text: "ballots" },
      ],
      unitSelected: "",
      loadComponent: false,
      isOnHarvest: null
    };
  },
  components: {
    Dropdown,
  },
  async mounted() {
    // En mode modification, on récupère l'unité courante
    this.loadComponent = false;
    this.isOnHarvest = this.$route.name.includes('harvestStorage');
    this.unitSelected = this.units[0].value;
    if (this.$route.name === "harvestStorage.edit") {
      this.unitSelected = this.currentEntry.unit;
    }
    if (this.$route.name === "activity.edit" && this.harvests.length > 0) {
      this.unitSelected = this.harvests[0].unit;
    }
    this.loadComponent = true;
  },

  methods: {
    /**
     * Description: Confirme le chargement des données de ce composant.
     * @return {Boolean} Component loaded.
     */
    validateForm: function() {
      this.isValid = true;
      return this.loadComponent;
    },
  },
  
  computed: {
    ...mapGetters({
      currentHarvest: "harvestStorage/currentEntry",
      harvests: "harvestStorage/harvests",
    }),
  },
  watch: {
    unitSelected: {
      handler: async function () {
        await this.$store.dispatch("harvestStorage/setEditedEntryValue", { unit: this.unitSelected });
      },
    },
  },
};
</script>

<style></style>
